@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

body {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  max-width: 1440px;
  margin: 0 auto;
  background-color: #000000;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Press Start 2P", system-ui;
}
#root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
